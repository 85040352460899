import React, { useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Box, Drawer, LoadingOverlay } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { useForm } from '@inertiajs/react'
import XSelect from '@/Mantine/XSelect'
import { updateShowLeadTagsDrawer } from '@/Store/leadSlice'
import axios from 'axios'

const AddLeadTagDrawer = () => {
  const dispatch = useDispatch()
  const leadStore = useSelector((state: RootState) => state.lead)
  const [loading, setLoading] = useState(false)

  const { data, setData, post, reset, errors, recentlySuccessful } = useForm<{
    lead_id: string | null
    tag: string | null
  }>({
    lead_id: '',
    tag: '',
  })

  useEffect(() => {
    if (leadStore.show_lead_tags_drawer) {
      setData((prev) => {
        return {
          ...prev,
          lead_id: leadStore.selected_lead != null ? leadStore.selected_lead.id.toString() : '',
          tag: leadStore.selected_lead != null ? leadStore.selected_lead.tag : '',
        }
      })
    }
  }, [leadStore.show_lead_tags_drawer])

  const onClose = () => {
    setLoading(false)
    dispatch(
      updateShowLeadTagsDrawer({
        show: false,
        selected_lead: null,
      })
    )
    reset()
  }

  useEffect(() => {
    if (recentlySuccessful) {
      onClose()
    }
  }, [recentlySuccessful])

  return (
    <Drawer
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      position={'right'}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      size={650}
      opened={leadStore.show_lead_tags_drawer}
      onClose={() => {
        onClose()
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <Box style={{ position: 'relative', width: '100%', height: '100%' }}>
        <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
        <VStack spacing="12px" height="100%">
          <HStack spacing="12px">
            <Typography variant="subheadline" color={XelaColor.Gray3}>
              {leadStore.selected_lead && leadStore.selected_lead.tag ? 'Update' : 'Add'} Tag
            </Typography>
          </HStack>
          <HStack>
            <Divider variant="dotted" />
          </HStack>
          <VStack spacing="8px" height="100%" className="relative">
            <VStack spacing="12px" className="flex-auto h-0 overflow-auto">
              <HStack>
                <XSelect
                  searchable
                  required
                  label={'Select Tag'}
                  error={errors.tag}
                  value={data.tag}
                  onInit={(callback) => {
                    axios
                      .post('/select/meta-options', {
                        entity_name: 'leads',
                        column_name: 'tags',
                      })
                      .then((response) => {
                        callback(response.data)
                      })
                      .catch(() => {
                        callback([])
                      })
                  }}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        tag: value,
                      }))
                    }
                  }}
                />
              </HStack>
            </VStack>
            <HStack spacing="12px" justifyContent="flex-end">
              <Button
                variant="secondary"
                label={'Cancel'}
                onClick={() => {
                  onClose()
                }}
              ></Button>
              <Button
                label={'Save'}
                onClick={() => {
                  setLoading(true)
                  post(
                    route('leads.update-tag', {
                      id: data.lead_id,
                    }),
                    {
                      preserveScroll: true,
                      preserveState: true,
                      onFinish: () => {
                        setLoading(false)
                      },
                    }
                  )
                }}
              ></Button>
            </HStack>
          </VStack>
        </VStack>
      </Box>
    </Drawer>
  )
}

export default AddLeadTagDrawer
