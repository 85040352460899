import React, { FC, useEffect, useState } from 'react'
import { Avatar, Box, Button, Center, ChipX, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router, usePage } from '@inertiajs/react'
import useAuth from '@/Hooks/useAuth'
import IconOff from '@/Icons/IconOff'
import IconDrawerClose from '@/Icons/IconDrawerClose'
import IconDrawerOpen from '@/Icons/IconDrawerOpen'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { SIDEBAR_ITEMS } from '@/Constants/SidebarItemsMeta'
import { IconSearch } from '@tabler/icons-react'
import { toggleCoachingSubSidebar, toggleSidebar, toggleSubSidebar, updateShowSearch } from '@/Store/globalSlice'
import useMeta from '@/Hooks/useMeta'
import { updateShowAddQuickLeadModal } from '@/Store/leadSlice'
import mixpanel from 'mixpanel-browser'
import useWidthResize from '@/Hooks/useWidthResize'
import SubSidebar from '@/Components/SubSidebar'
import { useClickOutside, useLocalStorage } from '@mantine/hooks'
import XImage from '@/Mantine/XImage'
import CoachingSubSidebar from '@/Components/CoachingSubSidebar'

const Sidebar: FC = () => {
  const auth = useAuth()
  const meta = useMeta()
  const dimension = useWidthResize()
  const dispatch = useDispatch()

  const [, setLoggedInValue] = useLocalStorage({
    key: 'CHgb9ZP1pyuUUIjm8rGmnM1TGEIfH1EKgab7Rae50UE',
    defaultValue: '6yjs7vZFPTxnKgP1B4wwvRAgs5MB_mOXNRo17a0xhXM',
  })

  const { url } = usePage()
  const user = useAuth().user
  const agency = useAuth().agency
  const avatar = useSelector((state: RootState) => state.user.avatar)
  const globalStore = useSelector((state: RootState) => state.global)
  const [isOpen, setOpen] = useState(true)
  const outsideRef = useClickOutside(() => {
    dispatch(toggleSubSidebar(false))
    dispatch(toggleCoachingSubSidebar(false))
  })

  // Check width and perform desired action
  useEffect(() => {
    if (dimension <= 1280) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [dimension])

  useEffect(() => {
    dispatch(toggleSidebar(isOpen))
  }, [isOpen])

  return (
    <div
      ref={outsideRef}
      className="sidebar"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '12px',
        height: '100vh',
        width: isOpen ? '260px' : '100px',
        backgroundColor: XelaColor.White,
        overflowX: !isOpen ? 'visible' : 'hidden',
        overflowY: 'auto',
      }}
    >
      <HStack justifyContent={isOpen ? 'space-between' : 'center'}>
        {isOpen && (
          <VStack spacing="4px">
            <VStack>
              <XImage
                src={agency.agency_logo ? agency.agency_logo : '/images/building.png'}
                alt={'Agency Logo'}
                defaultSrc={'/images/building.png'}
                style={{ height: 32 }}
              ></XImage>
            </VStack>
          </VStack>
        )}
        <Center
          className="sidebar-icon-btn"
          onClick={() => {
            setOpen(!isOpen)
          }}
          width="48px"
          height="48px"
        >
          {isOpen ? (
            <IconDrawerClose width="15" height="16" viewBox="0 0 15 16" />
          ) : (
            <IconDrawerOpen width="15" height="16" viewBox="0 0 15 16" />
          )}
        </Center>
      </HStack>

      <Center className="sidebar-icon-btn">
        <HStack spacing="8px">
          <Button
            autoresize={false}
            style={{ padding: '0', width: '70px', height: isOpen ? '48px' : '42px' }}
            leftIcon={<IconSearch size={isOpen ? 24 : 20}></IconSearch>}
            variant="secondary"
            onClick={() => {
              dispatch(updateShowSearch(true))
            }}
          ></Button>
          {isOpen && (
            <HStack
              bg={XelaColor.Blue3}
              borderRadius="16px"
              spacing="8px"
              height="48px"
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                dispatch(updateShowAddQuickLeadModal(true))
              }}
              style={{
                minWidth: '100px',
              }}
            >
              <Typography variant="body-small-bold" color={XelaColor.White}>
                Create New Lead
              </Typography>
            </HStack>
          )}
        </HStack>
      </Center>
      <Divider variant="dotted" />
      <VStack spacing="8px" className="grow">
        {SIDEBAR_ITEMS.filter((item) => {
          if (item.type === 'grow') {
            return true
          }

          // Check if user has both permissions and features to perform the action
          if (item.permissions && item.features) {
            // Allow if all permissions match or '*' is present in permissions
            const hasPermission =
              item.permissions.includes('*') ||
              item.permissions.some((permission) => auth.role.permissions.includes(permission))

            // Allow if all features match or '*' is present in features
            const hasFeature =
              item.features.includes('*') ||
              item.features.some((feature) => auth.agency.subscription_features.includes(feature))

            return hasPermission && hasFeature
          }

          // If either permissions or features are not present, do not allow
          return false
        }).map((item, index) => {
          if (item.type === 'divider') {
            return (
              <HStack key={index} style={{ position: 'relative', margin: '10px 0' }}>
                {isOpen ? <Divider variant="dotted" /> : <div></div>}
                {item.label && (
                  <Box
                    style={{
                      position: 'absolute',
                      top: '-8px',
                      left: isOpen ? '0' : item.label.split(' ')[0].length >= 8 ? '5%' : '15%',
                      textAlign: isOpen ? 'left' : 'center',
                      width: 'auto',
                      minHeight: '20px',
                      padding: '0 4px',
                      backgroundColor: XelaColor.White,
                      fontSize: isOpen ? '12px' : '10px',
                      color: XelaColor.Blue3,
                    }}
                  >
                    {isOpen ? item.label : item.label.length > 6 ? item.label.split(' ')[0] : item.label}
                  </Box>
                )}
              </HStack>
            )
          }

          if (item.type === 'grow') {
            return <Box key={index} className="grow"></Box>
          }

          const routeURL = item.route

          return (
            <HStack
              key={index}
              onClick={() => {
                dispatch(toggleSubSidebar(false))
                dispatch(toggleCoachingSubSidebar(false))
                if (routeURL) {
                  if (routeURL === '/b2b') {
                    window.open(route('b2b'), '_blank')
                  } else {
                    if (routeURL === '/settings') {
                      dispatch(toggleSubSidebar(!globalStore.isSubSidebarOpen))
                    } else if (routeURL === '/ielts-leads') {
                      dispatch(toggleCoachingSubSidebar(!globalStore.isCoachingSubSidebarOpen))
                    } else {
                      router.visit(routeURL, {
                        preserveState: false,
                        preserveScroll: false,
                      })
                    }
                  }
                }
              }}
              spacing="12px"
              justifyContent={isOpen ? 'flex-start' : 'center'}
              style={
                routeURL && url === routeURL
                  ? {
                      padding: '8px',
                      backgroundColor: XelaColor.Blue12,
                      borderRadius: '12px',
                      width: '100%',
                    }
                  : {
                      padding: '8px',
                    }
              }
              className="sidebar-icon-btn"
            >
              <Center width="18px" height="18px" style={{ minWidth: '18px' }}>
                {item.icon}
              </Center>
              {isOpen && (
                <>
                  <Typography variant="body-small-bold">{item.label}</Typography>
                  {(routeURL === '/tasks' || routeURL === '/follow-ups' || routeURL == '/b2b') && (
                    <ChipX
                      label={
                        routeURL === '/tasks'
                          ? meta.counts.tasks.toString()
                          : routeURL === '/follow-ups'
                          ? meta.counts.followUps.toString()
                          : meta.counts.b2bLeads.toString()
                      }
                      size="small"
                    ></ChipX>
                  )}
                </>
              )}
            </HStack>
          )
        })}
      </VStack>
      <HStack spacing="12px" justifyContent="center">
        <Avatar
          size="small"
          image={
            <XImage
              src={user.avatar !== '/images/avatar.png' ? user.avatar : avatar ? avatar : '/images/avatar.png'}
              alt={'User Avatar'}
              defaultSrc={'/images/avatar.png'}
            ></XImage>
          }
        />
        {isOpen && (
          <>
            <VStack
              style={{
                minWidth: dimension > 1440 ? '150px' : '130px',
                wordBreak: 'break-all',
              }}
            >
              <Typography variant="body-small-bold" color={XelaColor.Gray2}>
                {user.name}
              </Typography>
              <Typography variant="caption" color={XelaColor.Gray6}>
                {user.email}
              </Typography>
            </VStack>
            <Center width="16px" style={{ minWidth: '16px' }} className="sidebar-icon-btn">
              <IconOff
                width="16"
                height="16"
                viewBox="0 0 15 15"
                onClick={() => {
                  if (isProduction()) {
                    mixpanel.reset()
                  }

                  setLoggedInValue('6yjs7vZFPTxnKgP1B4wwvRAgs5MB_mOXNRo17a0xhXM')

                  router.post('/logout')
                }}
              ></IconOff>
            </Center>
          </>
        )}
      </HStack>
      <SubSidebar />
      <CoachingSubSidebar />
    </div>
  )
}

export default Sidebar
