import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Counselling = App.Models.Counselling

export interface CounsellingState {
  counselling_id: string
  is_converted: boolean
  is_cold: boolean
  cold_reason: string
  cold_status: string
  counsellor_id: string
  counsellor_id_one: string
  counsellor_id_two: string
  counsellor_id_three: string
  is_counsellor_changed: boolean
  branch_id: string | null
  reload_all_counselling_leads: boolean
  current_counselling_leads_filters: {
    [key: string]: string
  }
  previous_counselling_leads_filters: {
    [key: string]: string
  }
  show_all_counselling_leads_filter_modal: boolean
  is_branch_changed: false
  selected_counselling_ids: string[]
  selected_counsellings: Counselling[]
  quick_action_show_counselling_counsellor_modal: boolean
  branchIds: string[]
  leadManagerIds: string[]
  show_information_form: boolean
  counselling_status: string | null
  selected_counselling: Counselling | null
}

const initialState: CounsellingState = {
  counselling_id: '',
  is_converted: false,
  is_cold: false,
  cold_reason: '',
  cold_status: '',
  counsellor_id: '',
  counsellor_id_one: '',
  counsellor_id_two: '',
  counsellor_id_three: '',
  is_counsellor_changed: false,
  branch_id: null,
  reload_all_counselling_leads: false,
  current_counselling_leads_filters: {
    branch_id: '',
    counsellor_id: '',
    lead_form_id: '',
    lead_source: '',
    sub_lead_source: '',
    preferred_country: '',
    created_at: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
    intake: '',
    tag: '',
  },
  previous_counselling_leads_filters: {
    branch_id: '',
    counsellor_id: '',
    lead_form_id: '',
    lead_source: '',
    sub_lead_source: '',
    preferred_country: '',
    created_at: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
    intake: '',
    tag: '',
  },
  show_all_counselling_leads_filter_modal: false,
  is_branch_changed: false,
  selected_counselling_ids: [],
  selected_counsellings: [],
  quick_action_show_counselling_counsellor_modal: false,
  branchIds: [],
  leadManagerIds: [],
  show_information_form: false,
  counselling_status: null,
  selected_counselling: null,
}

export const counsellingSlice = createSlice({
  name: 'counsellingSlice',
  initialState,
  reducers: {
    updateSelectedCounsellingId(state, action: PayloadAction<string>) {
      state.counselling_id = action.payload
    },
    updateIsConverted(state, action: PayloadAction<boolean>) {
      state.is_converted = action.payload
    },
    updateIsCold(state, action: PayloadAction<boolean>) {
      state.is_cold = action.payload
    },
    updateColdLeadReason(state, action: PayloadAction<string>) {
      state.cold_reason = action.payload
    },
    updateColdLeadStatus(state, action: PayloadAction<string>) {
      state.cold_status = action.payload
    },
    updateIsCounsellorChanged(
      state,
      action: PayloadAction<{
        show: boolean
        counsellor_id: string
        counsellor_id_one: string
        counsellor_id_two: string
        counsellor_id_three: string
        counselling_id: string
      }>
    ) {
      state.is_counsellor_changed = action.payload.show
      state.counsellor_id = action.payload.counsellor_id
      state.counsellor_id_one = action.payload.counsellor_id_one
      state.counsellor_id_two = action.payload.counsellor_id_two
      state.counsellor_id_three = action.payload.counsellor_id_three
      state.counselling_id = action.payload.counselling_id
    },
    updateCounsellingBranchId(state, action: PayloadAction<string | null>) {
      state.branch_id = action.payload
    },
    showAllCounsellingLeadFilterModal(state) {
      state.show_all_counselling_leads_filter_modal = true
    },
    hideAllCounsellingLeadFilterModal(state) {
      state.show_all_counselling_leads_filter_modal = false
    },
    reloadAllCounsellingLeads(state, action: PayloadAction<boolean>) {
      state.reload_all_counselling_leads = action.payload
    },
    updateCurrentCounsellingLeadFilters(state, action: PayloadAction<{ [key: string]: string }>) {
      state.current_counselling_leads_filters = action.payload
    },
    updatePreviousCounsellingLeadFilters(state, action: PayloadAction<{ [key: string]: string }>) {
      state.previous_counselling_leads_filters = action.payload
    },
    updateSelectedCounsellingIds(state, action: PayloadAction<string[]>) {
      state.selected_counselling_ids = action.payload
    },
    updateSelectedCounsellings(state, action: PayloadAction<Counselling[]>) {
      state.selected_counsellings = action.payload
    },
    updateQuickActionShowCounsellingCounsellorModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
      }>
    ) {
      state.quick_action_show_counselling_counsellor_modal = action.payload.show
      state.branch_id = action.payload.branch_id
    },
    updateBranchIds(state, action: PayloadAction<string[]>) {
      state.branchIds = action.payload
    },
    updateLeadManagerIds(state, action: PayloadAction<string[]>) {
      state.leadManagerIds = action.payload
    },
    updateShowInformationFormDrawer(state, action: PayloadAction<boolean>) {
      state.show_information_form = action.payload
    },
    updateCounsellingStatus(state, action: PayloadAction<string | null>) {
      state.counselling_status = action.payload
    },
    updateSelectedCounselling(state, action: PayloadAction<Counselling | null>) {
      state.selected_counselling = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateSelectedCounsellingId,
  updateIsConverted,
  updateIsCold,
  updateColdLeadReason,
  updateColdLeadStatus,
  updateIsCounsellorChanged,
  updateCounsellingBranchId,
  showAllCounsellingLeadFilterModal,
  hideAllCounsellingLeadFilterModal,
  reloadAllCounsellingLeads,
  updateCurrentCounsellingLeadFilters,
  updatePreviousCounsellingLeadFilters,
  updateSelectedCounsellingIds,
  updateSelectedCounsellings,
  updateQuickActionShowCounsellingCounsellorModal,
  updateBranchIds,
  updateLeadManagerIds,
  updateShowInformationFormDrawer,
  updateCounsellingStatus,
  updateSelectedCounselling,
} = counsellingSlice.actions

export default counsellingSlice.reducer
